<script>
import modal from '/~/core/mdl'
import isEmpty from 'lodash-es/isEmpty'
import { RecroomAttachment } from '/~rec/core/attachment'
import BaseUploader from '/~/components/base/uploader/base-uploader.vue'
import BaseEmojis from '/~/components/base/emojis/base-emojis.vue'
import BaseGifs from '/~/components/base/gifs/base-gifs.vue'
import CreatorFile from './attachments/creator-file.vue'
import CreatorGif from './attachments/creator-gif.vue'
import BaseButton from '/~/components/base/button/base-button'
import { useRecProfile } from '/~rec/composables/profile'

export default {
  name: 'post-creator-footer',
  components: {
    BaseUploader,
    CreatorFile,
    BaseEmojis,
    CreatorGif,
    BaseGifs,
    BaseButton,
  },
  props: {
    attachments: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: undefined,
    },
    post: {
      type: Object,
      default: () => ({}),
    },
    isAndriod: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { features, isMeAdmin, isMeSuperAdmin } = useRecProfile()

    return { features, isMeAdmin, isMeSuperAdmin }
  },
  data() {
    return {
      collection: {},
      uploads: [...this.attachments],
    }
  },
  computed: {
    files() {
      return this.attachments.filter((a) => a.type !== 'image/gif')
    },
    gifs() {
      return this.attachments.filter((a) => a.type === 'image/gif')
    },
    isPostReady() {
      return !isEmpty(this.post)
    },
    isShowToken() {
      return this.features.points && (this.isMeAdmin || this.isMeSuperAdmin)
    },
  },
  watch: {
    uploads: {
      handler() {
        this.$emit('update:attachments', this.uploads)
      },
      deep: true,
    },
    attachments: {
      handler() {
        this.uploads = this.attachments
      },
      deep: true,
    },
  },
  methods: {
    onAttachClick() {
      this.$refs.attach.show()
    },
    onCameraClick() {
      this.$refs.camera.show()
    },
    onEmojisClick() {
      this.$refs.emojis.show()
    },
    onGifsClick() {
      this.$refs.gifs.show()
    },
    onTokensClick() {
      modal.show('rec-post-earn-config', {
        props: {
          attachments: this.files,
          type: this.type,
          post: this.post,
        },
        on: {
          save: (normalPayload, attachments) => {
            this.onAttachmentConfig(attachments)
            this.collection = {
              ...normalPayload,
              ...this.collection,
            }
          },
        },
      })
    },
    onAttachmentConfig(data) {
      if (!data.length) return
      /**
       * Keep gifs as it will not have earn_config.
       * Other types of attachments will get replace from 'data' with earn_config attribute
       */
      let items = this.gifs

      data.forEach((attachment) => {
        const item = new RecroomAttachment(attachment)

        items = [...items, item]
      })
      this.$emit('update:attachments', items)
    },
    onAddAttachment(attachment) {
      this.uploads.push(new RecroomAttachment(attachment))
    },
    onDeteleAttachment(attachment) {
      const attachments = this.attachments.filter((a) => a !== attachment)

      this.$emit('update:attachments', attachments)
    },
    onSend() {
      if (!this.disabled) this.$emit('send', this.collection)
    },
  },
}
</script>

<template>
  <div>
    <div
      v-if="files && files.length"
      class="-mx-2.5 flex flex-wrap px-6 py-2.5"
    >
      <creator-file
        v-for="(file, idx) in files"
        :key="idx"
        :attachment="file"
        removable
        class="mx-2.5 mb-2.5"
        @delete="onDeteleAttachment"
      />
    </div>

    <div v-if="gifs && gifs.length" class="-mx-2.5 flex flex-wrap px-6 py-2.5">
      <creator-gif
        v-for="(gif, idx) in gifs"
        :key="idx"
        :gif="gif"
        removable
        class="mx-2.5 mb-2.5"
        @delete="onDeteleAttachment"
      />
    </div>

    <div
      class="border-sepator mt-5 flex justify-between border-t px-6 py-[15px]"
    >
      <div
        v-if="isShowToken"
        class="flex items-center justify-center text-fg-warning"
      >
        <base-button icon="rec/token" size="md" @click="onTokensClick" />
      </div>
      <div class="flex items-center justify-center text-primary">
        <base-button icon="rec/camera" size="md" @click="onCameraClick" />
        <base-uploader ref="camera" camera multiple @upload="onAddAttachment" />
      </div>
      <div
        v-if="!isAndriod"
        class="relative flex items-center justify-center text-primary"
      >
        <base-button icon="rec/happy-face" size="md" @click="onEmojisClick" />
        <base-emojis ref="emojis" @select="$emit('emoji', $event)" />
      </div>
      <div class="flex items-center justify-center text-primary">
        <base-button icon="rec/gif" size="lg" @click="onGifsClick" />
        <base-gifs ref="gifs" @select="onAddAttachment" />
      </div>
      <div class="flex items-center justify-center text-primary">
        <base-button icon="rec/attachment" size="md" @click="onAttachClick" />
        <base-uploader ref="attach" multiple @upload="onAddAttachment" />
      </div>
      <div class="flex items-center justify-center text-primary">
        <base-button
          :class="{
            'cursor-default text-fg-disabled': disabled,
          }"
          icon="rec/paper-plane"
          size="xl"
          @click="onSend()"
        />
      </div>
    </div>
  </div>
</template>
