<script>
import BaseButton from '/~/components/base/button/base-button'

export default {
  name: 'creator-poll-option',
  components: {
    BaseButton,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    error: {
      type: [String, Array],
      default: '',
    },
    name: {
      type: String,
      default: null,
    },
  },
  emits: ['input', 'blur', 'focus', 'delete'],
  computed: {
    char() {
      return String.fromCharCode('a'.charCodeAt(0) + this.index)
    },
  },
}
</script>

<template>
  <div class="mb-[15px]">
    <div
      class="flex items-stretch justify-between rounded-xl border bg-divider-light px-5"
      :class="{
        'border-error': error,
        'border-divider-light': !error,
      }"
    >
      <div class="my-2.5 mr-5 flex items-center">
        <div
          class="flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-white text-xl font-bold uppercase text-primary"
        >
          {{ char }}
        </div>
      </div>
      <label class="flex flex-1 cursor-text items-center py-2.5">
        <textarea-autosize
          :maxlength="280"
          :value="value"
          :name="name"
          :rows="1"
          class="font-bold leading-5 text-black"
          placeholder="Write option here"
          @input="$emit('input', $event)"
          @blur="$emit('blur')"
          @focus="$emit('focus')"
        />
      </label>
      <base-button
        v-if="removable"
        class="ml-[15px] text-primary"
        icon="plain/bin"
        color="primary"
        size="md"
        @click="$emit('delete')"
      />
    </div>
    <div v-if="error" class="mt-[5px] text-xs font-bold text-fg-error">
      {{ error }}
    </div>
  </div>
</template>
